import React, { useEffect, useRef, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LayoutCekilis from "components/layout-cekilis"
import { RaffleTypes } from "components/layout-cekilis"
import SEO from "components/seo"
import { faBolt } from "@fortawesome/free-solid-svg-icons"
import { getRandomInt } from "utils/common"
import useTranslations from "components/use-translations"

export default function RastgeleSayi() {
  const {
    cekilis: {
      rastgeleSayiSeoTitle,
      rastgeleSayiSeoDesc,
      rastgeleSayiUret,
      sayiAraligi,
      minimum,
      maksimum,
      uret,
    },
  } = useTranslations()
  const [min, setMin] = useState(1)
  const [max, setMax] = useState(100)
  const [result, setResult] = useState("")
  const audioRef = useRef({ current: null })
  const audioIsAvalible = audioRef.current && audioRef.current.volume

  useEffect(() => {
    if (audioIsAvalible) audioRef.current.src = "/sounds/number.mp3"
  }, [])

  const generateRandomNumber = runCount => {
    const randomNumber = getRandomInt(min, max)
    setResult(randomNumber)

    if (runCount === 0) {
      audioRef.current.currentTime = 0
      audioRef.current.play()
    }
    if (runCount < 10) setTimeout(() => generateRandomNumber(runCount + 1), 120)
  }
  return (
    <>
      <LayoutCekilis
        headerText={rastgeleSayiUret}
        type={RaffleTypes.RastgeleSayi}
      >
        <SEO
          title={rastgeleSayiSeoTitle}
          description={rastgeleSayiSeoDesc}
          pathname="https://www.kodvizit.com/cekilis/rastgele-sayi"
          datePublished="2017-02-08T22:39:04+00:00"
          dateModified="2020-08-05T16:53:13+00:00"
        />
        <div className="d-flex flex-column ">
          <audio ref={audioRef} />

          <div
            className="text-center mb-3 font-weight-bold"
            style={{ fontSize: 16 }}
          >
            {sayiAraligi} :
          </div>
          <div className="d-flex flex-row justify-content-center  ">
            <label
              htmlFor="kazanan"
              className="text-dark  mt-1 mb-0 text-right  mr-2"
              style={{ width: 90 }}
            >
              {minimum} :
            </label>
            <input
              style={{ maxWidth: 210 }}
              type="number"
              max={max}
              min="-9999999"
              value={min}
              onChange={e => setMin(e.target.value)}
              className="form-control"
            />
          </div>
          <div className="d-flex flex-row justify-content-center  mt-2 ">
            <label
              htmlFor="kazanan"
              className="text-dark  mt-1  text-right mr-2"
              style={{ width: 90 }}
            >
              {maksimum} :
            </label>
            <input
              type="number"
              value={max}
              max="9999999"
              min={min}
              onChange={e => setMax(e.target.value)}
              style={{ maxWidth: 210 }}
              className="form-control"
            />
          </div>
          <div className="d-flex justify-content-center">
            <button
              onClick={() => {
                if (audioIsAvalible) audioRef.current.src = "/sounds/number.mp3"
                generateRandomNumber(0)
              }}
              className="btn btn-info mb-4 mt-3"
            >
              <FontAwesomeIcon icon={faBolt} className="mr-1" />
              <span>{uret}</span>
            </button>
          </div>

          <div className="mx-auto " style={{ fontSize: 80, marginBottom: 120 }}>
            {result}
          </div>
        </div>
      </LayoutCekilis>
    </>
  )
}
