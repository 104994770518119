export function UpdatePWAOnIOS() {
  const isIOS =
    !!navigator.userAgent && /iPad|iPhone|iPod/.test(navigator.userAgent)
  if (isIOS) {
    const currentVersion = localStorage.getItem("buildVersion")
    const url = `/build-version.txt?c=${Date.now()}`
    const headers = {
      "Cache-Control": "no-cache, no-store, must-revalidate",
    }

    fetch(url, { headers })
      .then(response => response.text())
      .then(data => {
        data = data.trim()
        if (data !== currentVersion) {
          localStorage.setItem("buildVersion", data)
          window.location.reload()
        }
      })
  }
}

export function ShuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}
